import {v4 as uuidv4} from 'uuid';

import type {ShopLoginButtonProps} from '~/features/ShopLoginButton/types';
import {Bugsnag} from '~/foundation/Bugsnag/Bugsnag';
import {Monorail} from '~/foundation/Monorail/Monorail';
import type {AnalyticsData} from '~/foundation/Monorail/types';
import {removeTrailingSlash} from '~/utils/browser';
import {toDashedCase} from '~/utils/casing';
import {createCustomElement} from '~/utils/customElement';
import {isoDocument} from '~/utils/document';
import {
  createElementLocator,
  createElementVisibilityObserver,
} from '~/utils/dom';
import {AbstractShopJSError} from '~/utils/errors';
import {getFeatures} from '~/utils/features';
import InputListener from '~/utils/input';
import {setStorageItem} from '~/utils/storage';
import {isoWindow} from '~/utils/window';

import {
  FedCMCancelledError,
  FedCMNotSupportedError,
  initFedCM,
} from '../initFedCM/initFedCM';

type AnalyticsBaseData = Required<
  Pick<
    AnalyticsData,
    | 'analyticsContext'
    | 'analyticsTraceId'
    | 'apiKey'
    | 'flow'
    | 'flowVersion'
    | 'shopPermanentDomain'
  >
>;

interface InitSignInPageOptions {
  autoOpen: boolean;
  isWindoidOrRedirect: boolean;
  returnUri?: string;
}

export async function initCustomerAccounts(
  invokeFedCM = false,
  isWindoidOrRedirect = false,
) {
  const analyticsTraceId = uuidv4();
  const bugsnag = new Bugsnag('initCustomerAccounts');

  const analyticsData: AnalyticsBaseData = {
    analyticsContext: 'loginWithShopClassicCustomerAccounts',
    analyticsTraceId,
    apiKey: '',
    flow: 'classic_customer_accounts',
    flowVersion: 'sign_in',
    shopPermanentDomain: isoWindow.Shopify?.shop ?? '',
  };

  const monorailTracker = new Monorail({
    analyticsData,
    notify: bugsnag.notify,
  });

  try {
    const pathName = removeTrailingSlash(isoWindow.location.pathname);
    if (pathName.endsWith('/account')) {
      initClassicCustomerAccountsAccountPage();
      return;
    }
    if (invokeFedCM) {
      initClassicCustomerAccountsSignInFedCM();
    }
    initClassicCustomerAccountsSignInForm({
      autoOpen: !invokeFedCM,
      isWindoidOrRedirect,
    });
  } catch (error) {
    if (error instanceof Error) {
      bugsnag.notify(error);
    }
    if (
      error instanceof AbstractShopJSError &&
      error.name === 'InitCustomerAccountsError'
    ) {
      monorailTracker.produceMonorailEvent({
        event: {
          payload: {
            ...analyticsData,
            analyticsTraceId: error.analyticsTraceId || analyticsTraceId,
            errorCode: error.code,
            errorMessage: error.message,
            sdkVersion: '__buildVersionBeta',
          },
          schemaId: 'shopify_pay_login_with_shop_sdk_error_events/1.0',
        },
      });
    }
  }

  /**
   * Initialize Login with Shop on the customer account page (=user is signed in).
   */
  async function initClassicCustomerAccountsAccountPage() {
    monorailTracker.trackPageImpression({
      page: 'CLASSIC_CUSTOMER_ACCOUNTS_ACCOUNT_PAGE',
    });

    await import('~/features/ShopToastManager/loader');
  }

  /**
   * Initialize Sign in with Shop via FedCM on the customer account login page
   */
  function initClassicCustomerAccountsSignInFedCM() {
    // This effectively inforces fedCM to be used on the login page
    const form = isoDocument.querySelector(
      '#customer_login',
    ) as HTMLFormElement;

    if (!form) {
      return;
    }

    // if this script gets called, it means the  beta flag is enabled
    initFedCM({
      mediation: 'required',
      analyticsTraceId,
      monorailTracker,
    })
      .then((res) => fedCMSucceeded(res, form, analyticsTraceId, bugsnag))
      .catch((error) => fedCMFailed(error, bugsnag));
  }

  function initClassicCustomerAccountsSignInForm({
    autoOpen,
    isWindoidOrRedirect,
  }: InitSignInPageOptions) {
    const inputListenerMap = new WeakMap<HTMLInputElement, InputListener>();
    let shopLoginButton: HTMLElement | null = null;

    const elementVisibilityObserver =
      createElementVisibilityObserver<HTMLInputElement>({
        onFallback: (element) => {
          element.addEventListener('focus', handleInputFocus, {once: true});

          monorailTracker.produceMonorailEvent({
            event: {
              schemaId: 'shopify_pay_login_with_shop_sdk_error_events/1.0',
              payload: {
                ...analyticsData,
                sdkVersion: '__buildVersionBeta',
                errorCode: 'fallback_to_focus_event',
                errorMessage:
                  'Fallback to focus event for classic customer accounts',
              },
            },
          });
        },
        onVisible: (input: HTMLInputElement) =>
          createClassicCustomerAccounts({input, autoOpen}),
      });

    createElementLocator<HTMLInputElement>({
      onElementFound: (input) => elementVisibilityObserver.observe(input),
      selector:
        'form[data-login-with-shop-sign-in] input[type="email"],form[data-login-with-shop-sign-in] input[name="customer[email]"',
    });

    function handleInputFocus(event: FocusEvent) {
      const input = event.target as HTMLInputElement;
      createClassicCustomerAccounts({input, autoOpen});
    }

    function createClassicCustomerAccounts({
      input,
      autoOpen,
    }: {
      input: HTMLInputElement;
      autoOpen: boolean;
    }) {
      const form = input.form;

      if (!form) {
        bugsnag.notify(
          new Error('Email form missing for classic customer accounts'),
        );
        return;
      }

      if (inputListenerMap.has(input)) {
        bugsnag.notify(new Error('Input listener already exists for input'));

        inputListenerMap.get(input)?.destroy();
        inputListenerMap.delete(input);
      }

      // Init login form if it hasn't been initialized yet
      if (!shopLoginButton) {
        const returnUri = signInRedirectUri(
          form,
          getAnalyticsTraceIdFromElement(shopLoginButton),
        );

        shopLoginButton = initCustomerAccountsSignInPage({
          autoOpen,
          isWindoidOrRedirect,
          returnUri,
        });

        shopLoginButton.addEventListener('completed', () => {
          isoWindow.location.assign(returnUri);
        });

        shopLoginButton.addEventListener('modalclosed', () => {
          setStorageItem('signInWithShop:modalDismissed', 'true');
        });
      }

      // Track password manager usage to prevent Sign in with Shop action to avoid skewing our conversion metrics
      if (shopLoginButton.getAttribute('defer-modal-on-autofill')) {
        shopLoginButton.setAttribute('email-input-selector', `#${input.id}`);
      }

      // Add input listener to email input
      shopLoginButton.setAttribute('email', input.value);

      inputListenerMap.set(
        input,
        new InputListener(input, (value) => {
          shopLoginButton?.setAttribute('email', value);
        }),
      );

      // Add hidden analytics trace id to form
      const analyticsTraceIdHiddenInput = isoDocument.createElement(
        'input',
      ) as HTMLInputElement;
      analyticsTraceIdHiddenInput.type = 'hidden';
      analyticsTraceIdHiddenInput.name = 'login_with_shop[analytics_trace_id]';
      analyticsTraceIdHiddenInput.value =
        getAnalyticsTraceIdFromElement(shopLoginButton);
      form.appendChild(analyticsTraceIdHiddenInput);
    }
  }
}

function initCustomerAccountsSignInPage({
  autoOpen,
  isWindoidOrRedirect,
  returnUri,
}: InitSignInPageOptions) {
  let element: HTMLElement | null;
  let shouldAppendButton = false;

  // Finds a button on the page that does not have an action assigned to it (e.g. Follow)
  // Note: Follow will be a deprecated action in the near future.
  element = isoDocument.querySelector('shop-login-button:not([action])');

  if (!element) {
    element = createCustomElement('shop-login-button') as HTMLElement;
    shouldAppendButton = true;
  }

  updateElement({
    element,
    props: {
      autoOpen,
      consentChallenge: true,
      disableSignUp: true,
      ...(shouldAppendButton ? {hideButton: true} : {}),
      proxy: true,
      ...getFeatures(),
      ...(isWindoidOrRedirect && {
        responseType: 'code',
        ...(returnUri && {returnUri}),
        uxMode: 'windoid',
      }),
    },
  });

  if (shouldAppendButton) {
    isoDocument.body.appendChild(element);
  }

  return element;
}

interface UpdateElementProps {
  element: HTMLElement;
  props: Partial<ShopLoginButtonProps>;
}

const DEFAULT_PROPS: Partial<ShopLoginButtonProps> = {
  analyticsContext: 'loginWithShopClassicCustomerAccounts',
  clientId: '',
  flowVersion: 'sign_in',
};

function updateElement({element, props}: UpdateElementProps) {
  Object.entries({...DEFAULT_PROPS, ...props}).forEach(([key, value]) => {
    const attribute = toDashedCase(key);

    element.setAttribute(attribute, String(value));
  });
}

function fedCMSucceeded(
  response: Response | undefined,
  form: HTMLFormElement,
  analyticsTraceId: string,
  bugsnag?: Bugsnag,
) {
  if (response?.status !== 200) {
    bugsnag?.notify(new Error('FedCM failed to authenticate'));
    return;
  }

  if (!form) {
    bugsnag?.notify(
      new Error(
        'FedCM failed to find the nearest form to leverage sign in URL',
      ),
    );
    return;
  }
  const redirectUri = signInRedirectUri(form, analyticsTraceId);
  isoWindow.location.assign(redirectUri);
}

/**
 * IdentityCredentialError - sign out in a separate tab, then try to continue with FedCM.
 * NetworkError - dismiss FedCM prompt, then refresh the page.
 */
function fedCMFailed(error: any, bugsnag: Bugsnag): any {
  if (
    error instanceof FedCMNotSupportedError ||
    error instanceof FedCMCancelledError ||
    (error.name === 'IdentityCredentialError' &&
      error.message === 'Error retrieving a token.') ||
    (error instanceof DOMException &&
      error.name === 'NetworkError' &&
      error.message === 'Error retrieving a token.')
  ) {
    return;
  }

  bugsnag.notify(error);
}

function signInRedirectUri(
  form: HTMLFormElement,
  analyticsTraceId: string,
): string {
  const checkoutInputUrl = (
    form.elements.namedItem('checkout_url') as HTMLInputElement | undefined
  )?.value;
  const returnInputUrl = (
    form.elements.namedItem('return_url') as HTMLInputElement | undefined
  )?.value;

  /* eslint-disable @typescript-eslint/naming-convention */
  const queryParams = new URLSearchParams({
    analytics_trace_id: analyticsTraceId,
    ...(checkoutInputUrl && {checkout_url: checkoutInputUrl}),
    ...(returnInputUrl && {return_url: returnInputUrl}),
  });

  /* eslint-enable @typescript-eslint/naming-convention */
  const redirectUri = `${
    isoWindow.location.origin
  }/account/redirect?${queryParams.toString()}`;

  return redirectUri;
}

function getAnalyticsTraceIdFromElement(element: HTMLElement | null) {
  return element?.getAttribute('data-instance-id') || uuidv4();
}
